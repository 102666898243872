var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "div-content" }, [
    _vm.isShowIndex
      ? _c(
          "div",
          [
            _c("div", { staticClass: "calendar-top" }, [
              _c(
                "div",
                { staticClass: "top-box" },
                [
                  _c("a-icon", {
                    style:
                      "color: " + (_vm.canSelectPreMounth ? "#d9d9d9" : ""),
                    attrs: { type: "left" },
                    on: { click: _vm.pervious },
                  }),
                  _c(
                    "div",
                    { staticClass: "timePicker dont-copy" },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "iconpark-icon calenderIcon",
                          on: { click: _vm.showPicker },
                        },
                        [_c("use", { attrs: { href: "#rongqi" } })]
                      ),
                      _c("a-month-picker", {
                        attrs: {
                          "disabled-date": _vm.disabledDate,
                          "value-format": "YYYYMM",
                          format: "YYYY年MM月薪资表",
                          open: _vm.isMonthPicker,
                          "get-calendar-container": function (trigger) {
                            return trigger.parentNode
                          },
                        },
                        on: { openChange: _vm.onChanges },
                        model: {
                          value: _vm.time,
                          callback: function ($$v) {
                            _vm.time = $$v
                          },
                          expression: "time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("a-icon", {
                    style:
                      "color: " + (_vm.canSelectNextMounth ? "#d9d9d9" : ""),
                    attrs: { type: "right" },
                    on: { click: _vm.next },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "bottom-box dont-copy" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.salaryPeriod.startDate) +
                    " ~ " +
                    _vm._s(_vm.salaryPeriod.endDate) +
                    " "
                ),
              ]),
            ]),
            _c(
              "CbStatistics",
              {
                staticClass: "statistics",
                attrs: {
                  "data-list": _vm.allDataList,
                  length: "4",
                  "is-logo": true,
                  "is-function": true,
                  "min-width": 340,
                },
              },
              [
                _c("template", { slot: "logo" }, [
                  _vm.status == 2
                    ? _c("div", { staticClass: "logo" }, [_vm._v(" 已锁定 ")])
                    : _vm._e(),
                ]),
                _c("template", { slot: "right" }, [
                  _c(
                    "div",
                    { staticClass: "right-btns" },
                    [
                      _vm.status === "2" &&
                      (!_vm.applyStatus ||
                        ["3", "4", "100"].includes(_vm.applyStatus))
                        ? [
                            _c(
                              "a-button",
                              {
                                attrs: { prefix: "audit-5o5mjp9j" },
                                on: { click: _vm.paySalaryProcess },
                              },
                              [_vm._v(" 发薪审批 ")]
                            ),
                          ]
                        : _vm._e(),
                      _vm.status === "1" && _vm.isAllComplete
                        ? [
                            _c(
                              "a-button",
                              {
                                attrs: { prefix: "lock" },
                                on: { click: _vm.lockSalaryPeriod },
                              },
                              [_vm._v(" 锁定 ")]
                            ),
                          ]
                        : _vm._e(),
                      _vm.status === "2" && _vm.isAllComplete
                        ? [
                            _c(
                              "a-button",
                              {
                                attrs: { prefix: "unlock" },
                                on: { click: _vm.unLockSalaryPeriod },
                              },
                              [_vm._v(" 解锁 ")]
                            ),
                          ]
                        : _vm._e(),
                      _vm.status === "2" && _vm.applyStatus === "2"
                        ? _c(
                            "a-button",
                            {
                              staticClass: "ant-button",
                              on: { click: _vm.sendImmediatelyStaffPayroll },
                            },
                            [_vm._v("前往工资条")]
                          )
                        : _vm._e(),
                      _vm.status === "2" && _vm.applyStatus
                        ? _c("CbStatusTag", {
                            attrs: {
                              "is-click": false,
                              "type-list": {
                                1: {
                                  name: "发薪审批中",
                                },
                                2: {
                                  name: "发薪审批已通过",
                                },
                                3: {
                                  name: "发薪审批未通过",
                                },
                                4: {
                                  name: "发薪审批被驳回",
                                },
                              },
                              type: _vm.applyStatus,
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ],
              2
            ),
            !["2", "6"].includes(_vm.status)
              ? _c("Alert", {
                  attrs: { type: "info", closable: "", "show-icon": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "flex-align-center" },
                              [
                                _vm._v(
                                  " 1.如需新增薪资组，可前往【设置-薪资组】进行 "
                                ),
                                _c(
                                  "a-button",
                                  {
                                    staticClass: "nav",
                                    attrs: { type: "link" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.noCompensation(1)
                                      },
                                    },
                                  },
                                  [_vm._v("去设置")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex-align-center" },
                              [
                                _vm._v(
                                  " 2.有" +
                                    _vm._s(_vm.tipInfo.notIncludedNum || 0) +
                                    "名人员暂无薪资组、" +
                                    _vm._s(_vm.tipInfo.ignoreNum || 0) +
                                    "名人员不参与计薪 "
                                ),
                                _c(
                                  "a-button",
                                  {
                                    staticClass: "nav",
                                    attrs: { type: "link" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.noCompensation(2)
                                      },
                                    },
                                  },
                                  [_vm._v("去设置")]
                                ),
                              ],
                              1
                            ),
                            _vm.tipInfo.otherCalcGroupNum
                              ? _c("div", [
                                  _vm._v(
                                    " 3.有" +
                                      _vm._s(_vm.tipInfo.otherCalcGroupNum) +
                                      "名人员未启用薪资组或即将启用薪资组 "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3915033038
                  ),
                })
              : _vm._e(),
            _vm._l(_vm.wageList, function (item, index) {
              return _c("div", { key: index, staticClass: "wage" }, [
                _c("div", { staticClass: "wage-left" }, [
                  _c(
                    "div",
                    { staticClass: "wage-left-title" },
                    [
                      _vm._v(" " + _vm._s(item.groupName) + " "),
                      item.calcStatus != 5
                        ? _c("a-tag", { attrs: { color: "orange" } }, [
                            _vm._v(" 未生成 "),
                          ])
                        : item.calcStatus == 5
                        ? _c("a-tag", { attrs: { color: "green" } }, [
                            _vm._v(" 已完成 "),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.perNum) + "人"),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "wage-left-content" }, [
                    item.calcStatus == 5
                      ? _c("div", { staticClass: "wage-left-content" }, [
                          _vm._v(
                            " 人力成本：" +
                              _vm._s(
                                _vm._f("stateFormatNum")(item.laborCostSum || 0)
                              ) +
                              "元 "
                          ),
                          _c("span", {
                            staticStyle: { "margin-right": "24px" },
                          }),
                          _vm._v(
                            " 应付工资：" +
                              _vm._s(
                                _vm._f("stateFormatNum")(item.planSum || 0)
                              ) +
                              "元 "
                          ),
                          _c("span", {
                            staticStyle: { "margin-right": "24px" },
                          }),
                          _vm._v(
                            " 实发工资：" +
                              _vm._s(
                                _vm._f("stateFormatNum")(item.realSum || 0)
                              ) +
                              "元 "
                          ),
                        ])
                      : _c(
                          "div",
                          { staticClass: "button-content" },
                          [
                            _vm._l(
                              _vm.salaryGroupStatusList,
                              function (key, statusIndex) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key:
                                        "salaryGroupStatusList" + statusIndex,
                                      staticClass: "content",
                                    },
                                    [
                                      _c(
                                        "svg",
                                        { staticClass: "iconpark-icon" },
                                        [
                                          _c("use", {
                                            attrs: {
                                              href: key.status(item.calcStatus),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" " + _vm._s(key.name) + " "),
                                    ]
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "wage-right" },
                  [
                    item.calcStatus == 0
                      ? [
                          _c(
                            "a-button",
                            {
                              attrs: { prefix: "bendijisuan" },
                              on: {
                                click: function ($event) {
                                  return _vm.startCalc(item)
                                },
                              },
                            },
                            [_vm._v(" 开始计薪 ")]
                          ),
                        ]
                      : [
                          _c(
                            "a-button",
                            {
                              attrs: { prefix: "chakan-chakanmingxi" },
                              on: {
                                click: function ($event) {
                                  return _vm.toDetail(item)
                                },
                              },
                            },
                            [_vm._v(" 查看明细 ")]
                          ),
                          _vm.status !== "2"
                            ? [
                                item.calcStatus != 5
                                  ? _c(
                                      "a-button",
                                      {
                                        attrs: { prefix: "jixusuanxin18" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toContinue(item)
                                          },
                                        },
                                      },
                                      [_vm._v(" 继续计算")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "a-button",
                                  {
                                    attrs: { prefix: "quxiaojixin" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelSalary(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.calcStatus > 3
                                            ? "解锁重算"
                                            : "取消计薪"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                  ],
                  2
                ),
              ])
            }),
          ],
          2
        )
      : _c(
          "div",
          [
            _c("Process", {
              attrs: {
                "salary-item-info": _vm.salaryItemInfo,
                "opration-type": _vm.oprationType,
                "salary-period": _vm.salaryPeriod,
                period: _vm.time,
                "salary-group": _vm.wageList,
              },
              on: { goBack: _vm.goBack },
            }),
          ],
          1
        ),
    _c(
      "div",
      { staticClass: "model" },
      [
        _c("IgnoreModal", {
          attrs: {
            visible: _vm.visible,
            title: "无薪资组人员",
            "data-prompt": _vm.userList,
            placeholder: "请选择薪资组",
            "replace-group-key": "payrollGroupId",
            "replace-ignore-type-key": "needSalaryGroup",
            "select-list": _vm.wageList,
            "replace-fields": {
              label: "groupName",
              value: "payrollGroupId",
            },
          },
          on: { setPerson: _vm.setPerson, cancel: _vm.closeModel },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }